import "../styles/doublecontentbanner.scss";
import * as CubeTrackingAK from "./tracking/cube/AktionskommunikationsTracking";
import { doublecontentbanner, middle } from "./ENTDWindow";
import * as CubeTrackingAB from "./tracking/cube/AbTestTracking";
import * as GtmTrackingAB from "./tracking/gtm/AbTestTracking";

class DoubleContentbanner extends HTMLElement {
    private kampagneName: string | undefined | null;
    private observer: IntersectionObserver;
    private alreadyTracked: boolean;
    private internalEventsAbortController: AbortController | undefined;

    constructor() {
        super();
        this.observer = new IntersectionObserver(this.handleObservationEvent.bind(this), { threshold: 0.9 });
        this.alreadyTracked = false;
    }

    connectedCallback(): void {
        this.setupDOMEventHandling();
    }

    private handleObservationEvent(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !this.alreadyTracked) {
                CubeTrackingAK.trackView(this.kampagneName, middle, doublecontentbanner);
                const el = this.querySelector<HTMLDivElement>('[data-select="doublecontentbanner"]');
                const abTestHeaderName = el?.getAttribute("data-abtest-name");
                const abTestHeaderValue = el?.getAttribute("data-abtest-value");
                CubeTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                GtmTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                this.alreadyTracked = true;
            }
        });
    }

    disconnectedCallback(): void {
        this.observer.disconnect();
        this.internalEventsAbortController?.abort();
    }

    private setupDOMEventHandling(): void {
        this.internalEventsAbortController = new AbortController();
        this.kampagneName = this.querySelector<HTMLDivElement>('[data-select="doublecontentbanner"]')?.getAttribute("data-kampagne-name");
        this.observer.observe(this);
    }
}

customElements.define("camp-doublecontentbanner", DoubleContentbanner);
